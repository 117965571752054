import * as React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"

import parse from "html-react-parser"

const IndexPage = ({ pageContext }) => {
  const { frontmatter, html } = pageContext.edge
  const { title } = frontmatter
  return <>
    <Layout>
      <Seo title={title} lang={"hu"} />
      {parse(html)}
    </Layout>
  </>
}


export default IndexPage
